@keyframes floaty {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(15px);
    }
}

.laptop {
    animation: floaty 1.8s infinite alternate;
}